import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, Button, Dropdown, Menu } from 'antd';
import { ArrowLeftOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import DetailForm from './DetailForm';
import ResponseCard from './ResponseCard';
import './detail.css';
import LoginFromBackgroundImage from './assets/images/login-from-background-image.png'
const Detail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore();
  const auth = getAuth();
  const analytics = getAnalytics();
  const [response, setResponse] = useState('');
  const [userName, setUserName] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [topic, setTopic] = useState<string>('');
  const [grade, setGrade] = useState<string>('');
  const [standard, setStandard] = useState<string>('');
  const [additionalDetails, setAdditionalDetails] = useState<string>('');
  const [questionComplexity, setQuestionComplexity] = useState<string>('mix');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate('/views');
    } else {
      const searchParams = new URLSearchParams(location.search);
      let titleParam = searchParams.get('title');
      if (titleParam) {
        titleParam = (titleParam == 'Assignment Generator') ? 'Assignments' :titleParam;
        setTitle(titleParam);
        document.title = `Generate ${titleParam}`;
        logEvent(analytics, 'page_view', { page: 'Detail', id, title: titleParam });
      }
    }
  }, [id, navigate, analytics, location.search]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (auth.currentUser) {
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(userData.name);
        }
      }
    };

    fetchUserInfo();
  }, [auth, db]);

  const handleFormSubmit = () => {
    logEvent(analytics, 'submit_form', { id, title });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleEditInfo = () => {
    navigate('/edit-info');
  };

  const userInitials = userName ? userName.split(' ').map(name => name[0]).join('') : 'U';

  const userMenu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={handleEditInfo}>
        Edit Info
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="login-form-section detail-form-section">
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Button
              onClick={() => navigate(-1)}
              className="button details-goback-btn"
            >
              <i className="fa-solid fa-angle-left"></i>&nbsp; Go Back
            </Button>
          </div>
        </div>
        <div className="row justify-content-lg-center">
            <div className="col-md-12">
              <div className="row justify-content-md-center">
                <div className="col-md-9">
                  <Card className='login-form-card'>
                    <div className="detail-form-card-title-section">
                        <h2 className='login-from-card-title' style={{textAlign: "center"}}>{`Generate ${title}`}</h2>
                    </div>
                    {id && (
                      <DetailForm
                        id={id} // Pass `id` to `DetailForm` where it is needed
                        setResponse={setResponse}
                        setExportLoading={setLoading}
                        setTopic={setTopic}
                        setGrade={setGrade}
                        setStandard={setStandard}
                        onSubmit={handleFormSubmit}
                        setAdditionalDetails={setAdditionalDetails}
                        setQuestionComplexity={setQuestionComplexity}
                      />
                    )}
                    <ResponseCard
                      isLoading={loading}
                      initialResponse ={response}
                      topic={topic}
                      grade={grade}
                      card_id={id || ''} // Ensure `card_id` is passed as a string to `ResponseCard`
                      standard={standard}
                      additionalDetails={additionalDetails}
                      setResponse={setResponse}
                    />
                  </Card>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Detail;
